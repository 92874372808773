<template>
	<b-container fluid="true" style="margin: 5vw">
		<b-row align-h="center">
			<h2>Smart Module Information</h2>
		</b-row>
		<b-row>
			<b-col cols="3" sm="8" align-h="center">
				<select v-model="module" @change="module_changed($event)">
					<option value="xnergy-bbb-3">Hope Tech</option>
					<option value="xnergy-cs-0010">Certis Cisco PLQ</option>
					<option value="xnergy-cs-0013">Certis Cisco SIT @ Dover</option>
					<option value="xnergy-cs-0014">Certis Cisco Expo</option>
					<option value="xnergy-cs-0073">Certis Cisco MOF </option>
					<option value="xnergy-cs-0074">Certis Cisco OTH </option>
					<option value="xnergy-cs-0009">Youibot DHL</option>
					<option value="xnergy-cs-0058">Youibot DHL 2</option>
					<option value="xnergy-cs-0006">LinDe SG</option>
					<option value="xnergy-bbb-10">Youibot-001</option>
					<option value="xnergy-pi-2">Office 04-18</option>
					<option value="xnergy-cs-0005">ASTI</option>
					<option value="xnergy-cs-0011">Jaten (Tongren site stand 2)</option>
					<option value="xnergy-cs-0012">Jaten (Tongren site stand 5)</option>
					<option value="xnergy-bbb-9">Jaten</option>
					<option value="xnergy-cs-0024">OneBerry</option>
					<option value="xnergy-cs-0026">NCS</option>
					<option value="xnergy-cs-0020">Jaten (Tongren site stand 6)</option>
					<option value="xnergy-cs-0021">Jaten (Tongren site stand 4)</option>
					<option value="xnergy-cs-0022">Jaten (Tongren site stand 1)</option>
					<option value="xnergy-cs-0023">Jaten (Tongren site stand 3)</option>
					<option value="xnergy-cs-0050">Omron</option>
					<option value="xnergy-cs-0067">xnergy-cs-0067, Zenix 07</option>
					<option value="xnergy-cs-0032">Hyundai-WIA TX-A</option>
					<option value="xnergy-cs-0031">Hyundai-WIA TX-B</option>
					<option value="xnergy-cs-0054">Hyundai-WIA TX-C</option>
				</select>
			</b-col>
			<b-col md="auto">
				Device Type <b>({{ parallel ? "Parallel" : "Single" }})</b>
			</b-col>
		</b-row>
		<b-row class="h4" align="center">
			<b-col align-self="center">
				{{ module }}
				<b-icon
					v-if="module_status === 'online'"
					icon="bar-chart-fill"
					variant="success"
				></b-icon>
				<b-icon
					v-if="module_status !== 'online'"
					icon="question-circle"
					animation="throb"
					variant="danger"
				></b-icon>
				<br />
				Customer: {{ module_customer }}
				<br />
				Location: {{ module_location }}
			</b-col>
			<b-col align-self="center">
				Charger Voltage: {{ charger_voltage }}
				<br />
				Battery Voltage: {{ battery_voltage }}
				<br />
				Charger Current: {{ charger_current }}
				<b-icon
					v-if="charger_current > 0.5 && main_state == 6"
					icon="battery-charging"
					animation="fade"
					style="color: #7952b3"
				></b-icon>
			</b-col>
		</b-row>

		<b-row align-v="center">
			<b-col>
				<label class="float-right"> Select your time range: </label>
			</b-col>
			<b-col>
				<datetime-picker
					:show-helper-buttons="true"
					:switch-button-initial="true"
					@date-applied="datetime_selected"
				/>
			</b-col>
			<b-col>
				<b-button @click="plot" variant="outline-info">Plot</b-button>
				<b-button hidden @click="plot_random_data" variant="outline-info"
					>Plot Random</b-button
				>
			</b-col>
			<b-col>
				<b-icon
					v-if="plot_data_downloading"
					icon="cloud-download"
					animation="throb"
					style="color: #7952b3"
				></b-icon>
				<b-icon
					v-if="!plot_data_downloading"
					icon="cloud-check"
					variant="success"
				></b-icon>
			</b-col>
		</b-row>
		<b-row>
			<line-graph :plot_data="plot_data" />
		</b-row>
		<b-row>
			<b-form-group
				label="select the items shown on the line chart"
				class="w-100"
			>
				<b-form-checkbox-group
					class="plot_items_options"
					v-model="plot_items_selected"
					:options="plot_items_options"
					@change="plot_items_changed($event)"
				></b-form-checkbox-group>
			</b-form-group>
		</b-row>
	</b-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import firebase from "../firebase_init";
import * as utils from "../utils/utils.js";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
import LineGraph from "@/components/LineGraph";
import {
	collection_path,
	get_random_color,
	get_random_int,
	seconds_since_epoch,
} from "@/utils/utils";

const db = firebase.firestore();

export default {
	name: "SmartModule",
	components: {
		"line-graph": LineGraph,
		"datetime-picker": DatePicker,
	},
	data() {
		return {
			parallel: false,
			parallel_num: 0,

			modules: {},
			module: "xnergy-cs-0023",
			module_status: "",
			module_location: "unknown",
			module_customer: "unknown",
			module_checking_timer: 0,
			charging_history_realtime: [],
			charging_history_realtime_unsorted: {},
			charging_history_realtime_timestamp: 0,
			charging_history_realtime_sub: {},
			ch_realtime_unsorted_p: {},

			plot_items_options: [],
			plot_items_selected: [],
			plot_datetime_range: {},
			plot_data_raw: {},
			plot_data_datasets: {},
			plot_data: {},
			plot_data_downloading: false,
		};
	},
	computed: {
		main_state() {
			if (this.parallel) {
				return this.charging_history_realtime_unsorted.Main_state_ch0;
			} else {
				return this.charging_history_realtime_unsorted.Main_state;
			}
		},
		charger_voltage() {
			let d =
				this.charging_history_realtime_unsorted.sec_vout ||
				this.charging_history_realtime_unsorted.RCU_output_voltage;
			if (d) {
				d = d.toFixed(2);
			} else {
				d = "NA";
			}

			let d_sum = 0;
			if (this.parallel && this.parallel_num > 0) {
				for (const [c, c_d] of Object.entries(this.ch_realtime_unsorted_p)) {
					let d_temp = c_d.sec_vout || c_d.RCU_output_voltage;
					d_sum += d_temp;
				}
				d = d_sum / this.parallel_num;
				d = d.toFixed(2);
			}
			return d;
		},
		charger_current() {
			let d =
				this.charging_history_realtime_unsorted.sec_current ||
				this.charging_history_realtime_unsorted.RCU_output_current;
			if (d) {
				d = d.toFixed(2);
			} else {
				d = "NA";
			}
			let d_sum = 0;
			if (this.parallel && this.parallel_num > 0) {
				for (const [c, c_d] of Object.entries(this.ch_realtime_unsorted_p)) {
					let d_temp = c_d.sec_current || c_d.RCU_output_current;
					d_sum += d_temp;
				}
				d = d_sum;
				d = d.toFixed(2);
			}
			return d;
		},
		battery_voltage() {
			let d =
				this.charging_history_realtime_unsorted.sec_battery_voltage ||
				this.charging_history_realtime_unsorted.RCU_battery_voltage;
			if (d) {
				d = d.toFixed(2);
			} else {
				d = "NA";
			}
			let d_sum = 0;
			if (this.parallel && this.parallel_num > 0) {
				for (const [c, c_d] of Object.entries(this.ch_realtime_unsorted_p)) {
					let d_temp = c_d.sec_battery_voltage || c_d.RCU_battery_voltage;
					d_sum += d_temp;
				}
				d = d_sum / this.parallel_num;
				d = d.toFixed(2);
			}
			return d;
		},
	},
	methods: {
		items_color(item = "") {
			const plot_items = {
				sec_current: { color: "#4510FF" },
				sec_vout: { color: "#10ff1c" },
				sec_battery_voltage: { color: "#ff1070" },
				rf_ok: { color: "#DD00AA" },
			};
			if (plot_items[item]) {
				return plot_items[item].color;
			} else {
				return get_random_color();
			}
		},
		plot_items_changed: function (checked) {
			const plot_datasets = [];
			if (checked === undefined || checked.length === 0) {
				checked = [
					"sec_current",
					"sec_battery_voltage",
					"RCU_output_current",
					"RCU_output_current_ch0",
					"RCU_output_current_ch1",
					"RCU_output_current_ch2",
					"RCU_output_current_ch3",
					"RCU_output_current_ch4",
					"RCU_output_current_ch5",
					"RCU_battery_voltage",
					"RCU_battery_voltage_ch0",
					"RCU_battery_voltage_ch1",
					"RCU_battery_voltage_ch2",
					"RCU_battery_voltage_ch3",
					"RCU_battery_voltage_ch4",
					"RCU_battery_voltage_ch5",
				];
			}
			for (const [key, data] of Object.entries(this.plot_data_datasets)) {
				if (checked.includes(key)) {
					plot_datasets.push({
						label: key,
						fill: false,
						lineTension: 0,
						borderColor: this.items_color(key),
						fillColor: this.items_color(key),
						pointBackgroundColor: this.items_color(key),
						data: data,
					});
				}
			}

			this.plot_data = {
				datasets: plot_datasets,
			};
		},
		plot: function () {
			let days_num = 0;
			// get how many days in the selected range
			let start = moment();
			let end = moment();
			if (_.isEmpty(this.plot_datetime_range)) {
				start = moment().subtract(10, "minutes");
				end = moment();
			} else {
				start = moment(this.plot_datetime_range.start);
				end = moment(this.plot_datetime_range.end);
			}

			days_num = Math.round(moment.duration(end.diff(start)).asDays());
			
			if (Math.round(moment.duration(end.diff(start)).asHours()) > 23) {
				alert("Please select a time range that is at most 23 hours long.");
				return;
			}
			const timestamp_start = start.unix();
			const timestamp_end = end.unix();

			this.plot_data_raw = {};
			this.plot_data_datasets = {};

			this.plot_data_downloading = true;
			for (let i = 0; i <= days_num; i++) {
				const day = start.add(i, "days").toDate();
				const col_path = collection_path(day);
				// console.log(col_path, timestamp_start, timestamp_end)
				const doc_ref = db
					.collection(this.module)
					.doc("charging_history")
					.collection(col_path)
					.where("__name__", ">=", timestamp_start.toString())
					.where("__name__", "<=", timestamp_end.toString());

				doc_ref.get().then((query_snap) => {
					// console.log(query_snap)
					query_snap.forEach((doc) => {
						// console.debug("query result", doc.id, doc.data());
						this.plot_data_raw[doc.id] = doc.data();
					});
					this.plot_items_options.length = 0;

					if (this.parallel) {
						for (const [ts, set] of Object.entries(this.plot_data_raw)) {
							if ("unit" in set) {
								let ch = set.unit - 17;
								for (const [key, value] of Object.entries(set)) {
									let new_key = `${key}_ch${ch}`;
									set[new_key] = value;
									delete set[key];
									if (!this.plot_items_options.includes(new_key)) {
										this.plot_items_options.push(new_key);
									}
								}
							} else {
								this.plot_data_downloading = false;
								alert(
									"no unit(slave address) in doc, confirm you are checking a parallel device."
								);
								return;
							}
						}
					} else {
						for (const [ts, set] of Object.entries(this.plot_data_raw)) {
							for (const [key, value] of Object.entries(set)) {
								if (!this.plot_items_options.includes(key)) {
									this.plot_items_options.push(key);
								}
							}
						}
					}
					this.plot_items_options.sort();
					// console.log(this.plot_data_raw);
					// console.log(this.plot_items_options);
					this.plot_data_downloading = false;
					// make datasets for Line diagram
					for (const [ts, set] of Object.entries(this.plot_data_raw)) {
						for (const [key, value] of Object.entries(set)) {
							if (_.isEmpty(this.plot_data_datasets[key])) {
								this.plot_data_datasets[key] = [];
							}
							// console.log(ts, value)
							this.plot_data_datasets[key].push({
								x: new Date(ts * 1000),
								y: value,
							});
						}
					}
					// console.log(this.plot_data_datasets);
					this.plot_items_changed(this.plot_items_selected);
				});
			}
		},
		plot_random_data() {
			const len = get_random_int();
			const fake_data = new Array(len);
			const today = new Date();
			// let tomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 24)

			for (let i = 0; i < len; i++) {
				const d_ = new Date(today.getTime() + i * 2000);
				fake_data[i] = {
					x: d_.toISOString(),
					y: get_random_int(),
				};
			}
			this.plot_data = {
				datasets: [
					{
						label: "voltage",
						lineTension: 0,
						fill: false,
						data: fake_data,
					},
				],
			};
		},
		datetime_selected(d1, d2) {
			this.plot_datetime_range.start = d1;
			this.plot_datetime_range.end = d2;
		},
		module_changed(e) {
			// console.log(this.module, e, e.target.value)
			this.module_status = "unknown";
			this.module_customer = "unknown";
			this.module_location = "unknown";
			this.parallel = false;
			this.parallel_num = 0;

			this.charging_history_realtime_timestamp = 0;
			this.charging_history_realtime_sub(); // cancel the previous subscribe
			this.realtime_charging_history(this.module);
		},
		check_module_online() {
			if (this.module_checking_timer) {
				clearInterval(this.module_checking_timer);
			}
			this.module_checking_timer = setInterval(() => {
				const timestamp = utils.seconds_since_epoch();
				if (this.charging_history_realtime_timestamp + 10 < timestamp) {
					this.module_status = "unknown";
				} else {
					this.module_status = "online";
				}
			}, 200);
		},
		realtime_charging_history: function (module = "xnergy-cs-0023") {
			const that = this;

			{
				const basic_info_ref = db.collection(module).doc("charging_history");
				basic_info_ref
					.get()
					.then(function (doc) {
						if (doc.exists) {
							// console.log('Document data:', doc.data())
							that.module_customer = doc.data().customer || "NA";
							that.module_location = doc.data().location || "NA";
						} else {
							// doc.data() will be undefined in this case
							// console.log(doc)
							console.log("No such document for basic info!");
						}
					})
					.catch(function (error) {
						console.error("Error getting document:", error);
					});
			}
			const today = new Date();
			const timestamp = seconds_since_epoch(today);
			that.charging_history_realtime_sub = db
				.collection(module)
				.doc("charging_history")
				.collection(collection_path(today))
				.where("__name__", ">=", timestamp.toString())
				.onSnapshot((snap) => {
					that.charging_history_realtime = [];
					that.charging_history_realtime_unsorted = {};
					snap.docChanges().forEach((change) => {
						if (change.type === "added") {
							that.charging_history_realtime_unsorted = change.doc.data();
							that.charging_history_realtime_timestamp = parseInt(
								change.doc.id
							);
							if ("unit" in this.charging_history_realtime_unsorted) {
								this.parallel = true;
								let unit = this.charging_history_realtime_unsorted.unit;
								this.ch_realtime_unsorted_p[`ch${unit - 17}`] =
									this.charging_history_realtime_unsorted;
								this.parallel_num = Object.keys(
									this.ch_realtime_unsorted_p
								).length;
							}
							// console.log(this.ch_realtime_unsorted_p);
							// console.log('Added: ', change.doc.data())
						}
						if (change.type === "modified") {
							console.log("Modified: ", change.doc.data());
						}
						if (change.type === "removed") {
							console.log("Removed: ", change.doc.data());
						}
					});
				});
		},
	},

	mounted() {
		this.realtime_charging_history();
		this.check_module_online();
	},
};
</script>
<style>
.plot_items_options {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
</style>
