<template>
  <b-container fluid>
    <LineChart ref="chart" :chartData="plot_data" :options="chartSettings"></LineChart>
    <b-button @click="reset_zoom()" variant="outline-info">Reset Zoom</b-button>

  </b-container>
</template>

<script>
import LineChart from '@/ChartJavaScripts/LineChart'
import { get_random_int } from '@/utils/utils'

export default {
  name: 'LineGraph',
  components: {
    LineChart
  },
  props: {
    plot_data: {}
  },
  watch: {},
  data: function () {
    return {
      dataCollection: {},
      chartSettings: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        tooltips: {
          mode: 'index'
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'second'
            },
            distribution: 'series'
          }]
        },
        plugins: {
          zoom: {
            zoom: {
              enabled: true,
              drag: true,
              mode: 'x',
              speed: 0.1,
              limits: {
                max: 10,
                min: 0.5
              }
            }
          }
        }
      }
    }
  },
  methods: {
    reset_zoom () {
      this.$refs.chart.$data._chart.resetZoom()
    },
    randomData () {
      const len = get_random_int()
      const fake_data = new Array(len)
      const today = new Date()
      // let tomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 24)

      for (let i = 0; i < len; i++) {
        const d_ = new Date(today.getTime() + i * 2000)
        fake_data[i] = {
          x: d_.toISOString(),
          y: get_random_int()
        }
      }
      this.dataCollection = {
        datasets: [
          {
            label: 'GitHub Commits',
            data: fake_data
          }
        ]
      }
    }
  },
  computed: {},
  mounted () {
    // this.randomData()
  }
}
</script>
<style>

</style>
