function seconds_since_epoch (d = new Date()) {
  return Math.floor(d / 1000)
}

function collection_path (d = new Date()) {
  const today = d
  const year = today.getUTCFullYear()
  const month = today.getUTCMonth() + 1
  const day = today.getUTCDate()
  return year.toString() + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0') + ' +00:00'
}
function get_random_int () {
  return Math.floor(Math.random() * (50 - 5 + 1)) + 5
}
function get_random_color () {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
export {
  seconds_since_epoch,
  collection_path,
  get_random_int,
  get_random_color
}
